<template>
  <div class="home">
    <section class="main-container">
      <div class="headline-wrapper">
        <!-- <div @click="goBack()">go back</div> -->
        <div class="top-wrapper">
          <div class="headliner">{{ post.title }}</div>
        </div>
        <div class="bio-text">
          <span>{{ post.date }}</span>
        </div>
      </div>
      <hr style="color: blue" />
      <!-- Markdown content -->
      <div class="blog-wrapper">
        <!-- <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Settings</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog = false"> Save </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <iframe
              src="https://stories.nws.ai/1288500942/first-story/?opts=fb:1"
              frameborder="0"
              scrolling="no"
              style="
                width: 300px;
                height: 600px;
                position: relative;
                margin: 0 auto;
                display: block;
              "
            ></iframe>
          </v-card>
        </v-dialog> -->
        <div class="text-white" v-html="post.markdown"></div>
        <!-- <div class="text-white" v-html="post.body"></div> -->
      </div>
      <!--Image overlay - underlay -->
      <!-- <div class="container">
        <div class="the-brain"></div>
        <div class="brain-overlay"></div>
      </div> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { BIO } from '@/configs/static_text.js';
const url = 'https://portfolio-bc8b3.ue.r.appspot.com/data';
import axios from 'axios';
// import { butter } from '@/configs/buttercms';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      brain: require('../assets/brain.png'),
      bio: BIO,
      backSvg: require('../assets/arrow-back.svg'),
      post: {},
      type: '',
      id: '',
      dialog: false,
    };
  },
  created() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.getDetails();
  },
  methods: {
    async getDetails() {
      let response = await axios
        .post(`${url}/details`, { type: this.type, id: this.id })
        .then((response) => response.data);
      // let response = await butter.post.retrieve(this.$route.params.id);
      // console.log('deets', response.data.data);
      if (response) {
        this.post = response;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600&family=Nunito+Sans:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700&display=swap" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
* {
  font-family: 'Cardo', serif;
}

.top-wrapper {
  display: flex;
  width: 100%;
  color: #fff;
  width: 70%;
  text-align: center;
}
.icon-wrapper {
  //   width: 10%;
}
.main-container {
  position: relative;
  min-height: 100vh;
  // padding: 0 10%;
  // margin-bottom: 50px;
  margin: auto;
  width: 50%;
}

.about-container {
  position: relative;
  min-height: 500px;
  margin-top: 100px;
}
.headline-wrapper {
  position: relative;
  flex-direction: column;
  margin-top: 100px;
  margin-bottom: 20px;
  z-index: 999;
  display: flex;
  justify-content: center;
}

.headliner {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  text-align: left;
  text-decoration: underline;
}

.blog-wrapper {
  position: relative;
  flex-direction: row;
  justify-content: unset;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
  z-index: 999;

  .text-white {
    // font-family: 'Nunito Sans', sans-serif;
    font-family: 'DM Sans', sans-serif;
    text-align: left;
    font-size: 1.25rem;
    width: 100%;
  }
}
a {
  color: #d07919 !important;
}

.added-marg {
  margin-top: 100px;
}

.subtext {
  min-width: 600px;
  font-size: 2rem;
  color: #9c9c9c;
  font-weight: bold;
  text-align: left;
}

.text-white {
  color: #fff;
}

//card-container
.card-container {
  position: relative;
  max-width: 400px;
  margin-right: 50px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
}

.overscript {
  width: 50px;
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
}

.card-container:nth-child(n + 3) {
  margin-top: 75px;
}

/* Make the image to responsive */
.the-card {
  display: block;
  max-width: 350px;
  max-height: 300px;
  margin-top: 40px;
}

.titles {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  color: #fff;
  margin-top: 15px;
}

.date {
  font-size: 1rem;
  text-align: left;
  color: #8d8d8d;
}

/* The overlay effect - lays on top of the container and over the image */
.card-overlay {
  position: absolute;
  margin-left: 10px;
  background-color: orange;
}

//image css here
.container {
  position: absolute;
  top: 10%;
  right: 10%;
  max-width: 600px;
  height: 200px;
  width: 250px;
}

/* Make the image to responsive */
.the-brain {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.85;
}

/* The overlay effect - lays on top of the container and over the image */
.brain-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0.85;
}
.bio-text {
  font-size: 1.5rem;
  color: #fff;
  opacity: 0.85;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
}
@media only screen and (min-device-width: 350px) and (max-device-width: 736px) {
  .home {
    width: 100%;
  }

  .top-wrapper {
    color: #fff;
    width: 100%;
  }

  .main-container {
    position: relative;
    min-height: 100vh;
    margin: 0;
    overflow-x: hidden;
    padding: 0 5%;
  }

  .headliner {
    font-size: 2rem;
  }

  .blog-wrapper {
    justify-content: unset;
  }

  .container {
    position: absolute;
    top: 20%;
    left: 40%;
    max-width: 300px;
    height: 400px;
    width: 400px;
  }
}
</style>
